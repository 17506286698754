<script setup>

import {computed, ref, watch} from "vue";

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: String,
        default: null
    },
    name: {
        type: String,
        required: true
    },
    id: {
        type: String,
        required: false
    },
    setClass: {
        type: String,
        required: false
    },
    error: {
        type: String
    },
    hasError: {
        type: Boolean,
        required: true
    },
    partnerDisable: {
        type: Boolean,
        required: false,
        default: false
    },
    label: {
        type: String,
        required: true
    },
    suggestions: {
        type: Array,
        required: true
    },
    maxSuggestions: {
        type: Number,
        required: false,
        default: 8
    }
})

const open = ref(false)
const current = ref(0)
const input = ref(props.modelValue)
const initSuggestions = ref([])
const suggestions = ref([])


watch(() => props.modelValue, (newVal) => {
    input.value = newVal
})

watch(() => props.suggestions, (newVal) => {
    initSuggestions.value = JSON.parse(JSON.stringify(newVal));

    if (newVal.length > props.maxSuggestions) {
        newVal.length = props.maxSuggestions
    }
    suggestions.value = newVal
})

watch(input, (newVal) => {
    suggestions.value = initSuggestions.value.filter((str) => {
        return str.indexOf(newVal) >= 0;
    });
    if (suggestions.value.length > props.maxSuggestions) {
        suggestions.value.length = props.maxSuggestions
    }
})

watch(() => suggestions.value, () => {
    suggestions.value.length <= 0 ? closePicker() : null
})

const openSuggestions = computed(() => {
    return input.value !== "" &&
        suggestions.value.length !== 0 &&
        open.value === true;
})

function togglePicker() {
    open.value = !open.value;
}

function openPicker() {
    open.value = suggestions.value.length > 0
}

function closePicker() {
    open.value = false
}

function enter() {
    input.value = suggestions.value[current.value];
    closePicker()
}

function up() {
    if(current.value > 0)
        current.value--;
}

function down() {
    if(current.value < suggestions.value.length - 1)
        current.value++;
}

function isActive(index) {
    return index === current.value;
}

function change() {
    if (open.value === false) {
        openPicker()
        current.value = 0;
    }
    emit('update:modelValue', input.value)
}

function suggestionClick(index) {
    emit('update:modelValue', suggestions.value[index])
    input.value = suggestions.value[index]
    closePicker()
}

</script>
<template>
    <div :class="setClass" v-click-outside="closePicker">

        <input type="text" :id="id" class="form-control is-required"

               autocomplete="new-code"
               :class="{'is-invalid': props.hasError}"
               :placeholder="props.label"
               :name="props.name"
               :readonly="props.partnerDisable"
               v-model="input"
               @keydown.enter="enter"
               @keydown.down="down"
               @keydown.up="up"
               @input="change"
               @focus="openPicker"
               @click="openPicker"
        >
        <label :for="id" class="required">{{ props.label }}</label>

        <ul class="dropdown-menu dropdown-menu-light" style="width:100%; cursor: pointer" v-bind:class="{'d-block': open }">
            <li class="dropdown-item" v-for="(suggestion, index) in suggestions"
                @click.prevent="suggestionClick(index)"
            >
                <a href="#" style="color: #ffffff;font-weight: bold;">{{ suggestion }}</a>
            </li>
        </ul>

        <div v-if="props.hasError" class="invalid-feedback">
            {{ props.error }}
        </div>

    </div>
</template>

